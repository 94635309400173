import { useLazyQuery } from "@apollo/client";
import { GET_SEARCH_PRODUCT } from "../graphql/queries/searchProduct/searchProduct";

export const useSearchProducts = () => {
  const [onSearch, { data, loading, fetchMore }] = useLazyQuery(GET_SEARCH_PRODUCT, {
    fetchPolicy: 'no-cache'
  })
  const formatData = []
  data?.products?.edges.forEach((item, index) => {
    if (item.node.__typename === "VariableProduct") {
      item?.node?.variations?.nodes?.forEach(el => {
        formatData.push({ node: { ...item.node, name: el.name, variationId: el.databaseId, price: el.price, regularPrice: el.regularPrice, stockStatus:el.stockStatus }, __typename: item.__typename })
      })
    } else {
      formatData.push(item)
    }
  })
  return {
    onSearch: onSearch,
    fetchMore: fetchMore,
    searchProducts: formatData,
    searchLoading: loading,
    pageInfo: data?.products?.pageInfo
  }
}