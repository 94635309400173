import React, { Fragment, useState } from "react"

import "./QuickOrder.scss"
import Head from "./Head"
import Table from "./Table"
import Row from "./Row"
import ButtonOutline from "../ButtonOutline"
import { useSearchProducts } from "../../hooks/useSearchProducts"
import { useEffect } from "react"
import { useGetCategories } from "../../hooks/useGetCategory"
import { useMutation } from "@apollo/client"
import { ADD_CART_ITEMS } from "../../graphql/mutations/cart/addCartItems"
import { randomString } from "../../helpers/randomString"
import { useCartContext } from "../../views/cart/CartContext"
import Loading from "../Loading"
const index = () => {
  const [productsSelected, setProductsSelected] = useState([])
  const { onSearch, searchProducts, searchLoading } = useSearchProducts()
  const { refetch,setIsAddToCart } = useCartContext()
  const [addToCart, { loading: addToCartLoading }] = useMutation(ADD_CART_ITEMS, {
    refetchQueries: ["getSimpleCart"],
    awaitRefetchQueries: true,
    onCompleted: () => {
      // setIsProductInCart(true)
      refetch()
      setProductsSelected([])
      setIsAddToCart(true)
    }
  })
  const [searchParams, setSearchParams] = useState({
    search: "",
    category: ""
  })
  const { listCategories } = useGetCategories()
  const handleSelect = (id, qty) => {
    if (id) {
      const temp = searchProducts.find(item => item.node.databaseId === id)
      if (temp.node.__typename === "ProductVariation") {
        setProductsSelected([
          ...productsSelected,
          { productId: temp.node.databaseId, variationId: temp.node.variationId, quantity: qty }
        ])
      } else {
        setProductsSelected([
          ...productsSelected,
          { productId: temp.node.databaseId, quantity: qty }
        ])
      }
    }
  }
  const handleRemove = id => {
    if (id) {
      const temp = productsSelected.filter(item => item?.productId != id)
      setProductsSelected([...temp])
    }
  }
  const handleAddToCart = () => {
    addToCart({
      variables: {
        input: {
          clientMutationId: randomString(),
          items: [...productsSelected]
        }
      }
    })
  }
  useEffect(() => {
    onSearch({
      variables: {
        input: {
          categoryNotIn: "Wholesale",
          status: "PUBLISH"
        }
      }
    })
  }, [])
  return (
    <div className="quick-order">
      <Head
        listCategories={listCategories}
        onSearch={onSearch}
        searchParams={searchParams}
        setSearchParams={setSearchParams}
        handleAddToCart={handleAddToCart}
        addToCartLoading={addToCartLoading}
        productsSelected={productsSelected}
      />
      <Table>
        {searchLoading ? (
          <tr className="loading-wrapper">
            <Loading />
          </tr>
        ) : (
          <Fragment>
            {Array.isArray(searchProducts) && searchProducts.length > 0 ? (
              searchProducts.map((item, index) => {
                const { node } = item
                return (
                  <Row
                    key={index}
                    regularPrice={node.regularPrice}
                    isOutOfStock={node.stockStatus === "OUT_OF_STOCK"}
                    options={{
                      name: node?.name,
                      image: node?.featuredImage?.node?.sourceUrl,
                      slug: node.slug,
                      id: node.id,
                      databaseId:
                        node.__typename === "VariableProduct" ? node.variationId : node.databaseId
                    }}
                    productsSelected={productsSelected}
                    handleSelect={handleSelect}
                    handleRemove={handleRemove}
                  />
                )
              })
            ) : (
              <tr>
                <p className="no-product">No Products Found</p>
              </tr>
            )}
          </Fragment>
        )}
      </Table>
      <div class="total_products_container">
        <span class="total_products">{`${
          Array.isArray(searchProducts) ? searchProducts.length : 0
        } Product/s Found`}</span>
      </div>
    </div>
  )
}

export default index
