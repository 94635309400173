import React from "react"
import QuickOrderComponent from "../../components/QuickOrder"
import Layout from "../../components/layout"
import "./quick-order.scss"
import Seo from "../../components/seo"
const QuickOrder = () => {
  const seo = {
    title:"Quick Order - Backa Australia",
    description:""
  }
  return (
    <Layout
      pageClass="quick-order-page"
    >
      <Seo title={seo.title} description={seo.description}/>
      <div className="container">
        <QuickOrderComponent />
      </div>
    </Layout>
  )
}

export default QuickOrder