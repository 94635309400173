import { gql } from "@apollo/client";

export const GET_LIST_CATEGORIES = gql`
query GET_LIST_CATEGORIES{
    productCategories {
        nodes {
          name
          databaseId
          id
        }
    }
}
  `