import React from "react"

import "./ProductPrice.scss"

const ProductPrice = ({
  regularPrice,
  salePrice,
  maxPrice,
  minPrice,
  isSales,
  isProductConfig
}) => {
  return (
    <div className={`price-group ${isSales ? "sales" : ""}`}>
      {isSales ? (
        <>
          <span className="regular-price">{regularPrice}</span>
          <span className="special-price">&nbsp;{salePrice}</span>
        </>
      ) : (
        <span className="regular-price">{regularPrice}</span>
      )}
    </div>
  )
}

export default ProductPrice
