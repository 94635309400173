import React, { useMemo, useState } from "react"
import ProductPrice from "../ProductPrice/ProductPrice.component"
import { Link } from "gatsby"

const ProductInfo = ({ options }) => {
  return (
    <div className="product-info">
      <div className="product-info-top">
        <Link to={`/product/${options?.slug}`}>
          <img
            // eslint-disable-next-line max-len
            src={options?.image}
            alt={options?.name}
          />
        </Link>
        <Link to={`/product/${options?.slug}`}>
          <span>{options?.name}</span>
        </Link>
      </div>
    </div>
  )
}

const Row = ({
  isOutOfStock,
  options,
  regularPrice,
  specialPrice,
  isSales,
  handleSelect,
  handleRemove,
  productsSelected
}) => {
  const [qty, setQty] = useState(1)
  const onCheck = e => {
    if (e.target.checked) {
      handleSelect(options.databaseId, qty)
    } else {
      handleRemove(options.databaseId)
    }
  }
  const defaultCheck = useMemo(() => {
    const temp = productsSelected.some(item => item?.productId === options.databaseId)
    return temp
  },[productsSelected])
  const handleQty = value => {
    const index = productsSelected.findIndex(item => item.productId === options.databaseId)
    if (index >= 0) {
      productsSelected[index].quantity = value
    }
    if (value > 0) {
      setQty(value)
    }
  }

  return (
    <tr>
      <td data-th="Product">
        <ProductInfo options={options} />
      </td>
      <td data-th="Price">
        <ProductPrice regularPrice={regularPrice} specialPrice={specialPrice} isSales={isSales} />
      </td>
      <td data-th="Quantity">
        {isOutOfStock ? (
          <span class="out-stock">Out of Stock</span>
        ) : (
          <input type="number" value={qty} onChange={({ target: { value } }) => handleQty(Number(value))} />
        )}
      </td>
      <td data-th="&nbsp;">
        {isOutOfStock ? (
          <span class="out-stock">Out of Stock</span>
        ) : (
          <label className="action-add-cart">
            <input
              type="checkbox"
              onChange={onCheck}
              checked={defaultCheck}
            />
            Add To Cart
          </label>
        )}
      </td>
    </tr>
  )
}

export default Row
