import React from "react"
import ButtonOutline from "../ButtonOutline"
const Head = props => {
  const { listCategories, onSearch, setSearchParams, searchParams,handleAddToCart,addToCartLoading,productsSelected } = props
  const handleChange = e => {
    setSearchParams({ ...searchParams, [e.target.name]: e.target.value })
  }
  const handleShowAll = () => {
    setSearchParams({search:'',category:""})
    onSearch({
      variables: {
        input:{
          categoryNotIn: "Wholesale",
          status: "PUBLISH"
        },
        first:10,
      }
    })
  }
  return (
    <div className="head">
      <div className="head-top">
        <input
          name="search"
          type="text"
          value={searchParams.search}
          placeholder="Search by name or SKU ..."
          onChange={handleChange}
        />
        <select name="category" id="" onChange={handleChange} value={searchParams.category}>
          <option value="">-- No Category Filter --</option>
          {listCategories.map((item, index) => {
            return (
              <option key={index} value={item.databaseId}>
                {item.name}
              </option>
            )
          })}
        </select>

        <ButtonOutline onClick={() => onSearch({
          variables: {
            input:{
              search:searchParams.search,
              categoryId:Number(searchParams.category),
              categoryNotIn: "Wholesale",
              status: "PUBLISH"
            },
            first:10,
          }
        })}>Search</ButtonOutline>
        <ButtonOutline onClick={() => handleShowAll()}>show all products</ButtonOutline>
      </div>
      <div className="head-bottom">
        <ButtonOutline onClick={handleAddToCart} disabled={addToCartLoading || productsSelected.length <= 0} className={`${addToCartLoading || productsSelected.length <= 0 ? 'disabled':''}`}>add selected products to cart</ButtonOutline>
      </div>
    </div>
  )
}

export default Head
