import React from "react"

const Table = ({
  children
}) => {
  return (
    <table>
      <thead>
        <tr>
          <th>Product</th>
          <th>Price</th>
          <th>Quantity</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {children}
      </tbody>
      <tfoot>
        <tr>
          <th>Product</th>
          <th>Price</th>
          <th>Quantity</th>
          <th></th>
        </tr>
      </tfoot>
    </table>
  )
}

export default Table