import { gql } from "@apollo/client";

export const GET_SEARCH_PRODUCT = gql`
query GetSearchProduct($input:RootQueryToProductConnectionWhereArgs){
    products(where: $input,first:100) {
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
      edges {
        node {
          ... on SimpleProduct {
            id
            name
            stockStatus
            stockQuantity
            regularPrice
            salePrice
            onSale
            sku
            databaseId
            featuredImage {
              node {
                sourceUrl
              }
            }
            description
            galleryImages {
              nodes {
                sourceUrl
              }
            }
            slug
          }
          ... on VariableProduct {
            id
            name
            stockStatus
            stockQuantity
            regularPrice
            salePrice
            sku
            onSale
            databaseId
            featuredImage {
              node {
                sourceUrl
              }
            }
            slug
            variations {
              nodes {
                id
                databaseId
                name
                salePrice(format: FORMATTED)
                price(format: FORMATTED)
                regularPrice(format: FORMATTED)
                stockStatus
              }
            }
          }
        }
      }
    }
  }
  `