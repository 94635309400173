import { gql } from "@apollo/client"

export const ADD_CART_ITEMS = gql`
mutation addCartItems($input: AddCartItemsInput!) {
  addCartItems(input: $input) {
    added {
      key
      quantity
      subtotal
      subtotalTax
      tax
      total
    }
    cartErrors {
      extraData
      productId
      quantity
      reasons
      type
      variationId
    }
    clientMutationId
  }
}
`