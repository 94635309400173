import { useQuery } from "@apollo/client";
import { GET_LIST_CATEGORIES } from "../graphql/queries/searchProduct/getCategory";

export const useGetCategories = () => {
  const { data } = useQuery(GET_LIST_CATEGORIES,{
    fetchPolicy:"cache-and-network"
  })
  return {
    listCategories:data?.productCategories ? data?.productCategories?.nodes : []
  }
}